import './Footer.module.css';

import {
  Button,
  Link
} from '@material-ui/core';
import { FreeBreakfastTwoTone } from '@material-ui/icons';
import React from 'react';

export default function Footer() {
  return (
    <div styleName="wrap">
      <div styleName="innerWrap">
        <div>
          <Link color="inherit" href="/about" styleName="displayBlock">About Us</Link>
          <Link color="inherit" href="/privacy_policy" styleName="displayBlock">Privacy Policy</Link>
        </div>
        <div styleName="spacer" />
        <Button
          color="inherit"
          href="https://www.buymeacoffee.com/morethansudoku"
          startIcon={<FreeBreakfastTwoTone />}
          target="_blank"
          variant="outlined"
        >
          Buy me a coffee
        </Button>
      </div>
      <div styleName="rights">© 2020 More than Sudoku, All Rights Reserved.</div>
    </div>
  );
}
