import './styles.module.css';

import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import downloadElementPdf from '../utils/downloadElementPdf';

export default function DownloadPdfButton({ children, elementId, fileName }) {
  const onDownloadPdfClick = async () => {
    const element = document.querySelector(elementId);
    downloadElementPdf(element, fileName);
  };

  return (
    <Button
      onClick={onDownloadPdfClick}
      styleName="button"
      variant="outlined"
    >
      {children}
    </Button>
  );
}

DownloadPdfButton.propTypes = {
  children  : PropTypes.node.isRequired,
  elementId : PropTypes.string.isRequired,
  fileName  : PropTypes.string.isRequired
};
