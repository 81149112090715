import './PlayBoard.module.css';

import {
  Button,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import { Settings as SettingsIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setNewGame, setShowSettings } from '../../store/actions';
import {
  getShowSettings,
  getSolutionBoard,
  getSudokuBoard
} from '../../store/selectors';
import DifficultyButtonGroup from '../DifficultyButtonGroup';
import DownloadJpegButton from '../DownloadJpegButton';
import DownloadPdfButton from '../DownloadPdfButton';
import SettingsDrawer from '../SettingsDrawer';
import SizeButtonGroup from '../SizeButtonGroup';
import SudokuTable from '../SudokuTable';

export default function PlayBoard() {
  const dispatch = useDispatch();
  const sudokuBoard = useSelector(getSudokuBoard);
  const solutionBoard = useSelector(getSolutionBoard);
  const showSettings = useSelector(getShowSettings);

  const [showSolution, setShowSolution] = useState(false);
  const [showMistakes, setShowMistakes] = useState(true);

  useEffect(() => {
    dispatch(setNewGame());
  }, []);

  useEffect(() => {
    setShowSolution(false);
  }, [sudokuBoard]);

  return (
    <>
      <SettingsDrawer title="Settings">
        <FormControlLabel
          control={(
            <Checkbox
              checked={showMistakes}
              color="primary"
              onChange={() => { setShowMistakes(!showMistakes); }}
            />
          )}
          label="Show mistakes"
        />
      </SettingsDrawer>
      <div styleName="topWrap">
        <DifficultyButtonGroup styleName="buttonGroup" />
        <SizeButtonGroup styleName="buttonGroup" />
      </div>
      {sudokuBoard && (
        <SudokuTable
          id="sudokuTable"
          isInteractive
          showMistakes={showMistakes}
          solutionBoard={solutionBoard}
          sudokuBoard={sudokuBoard}
        />
      )}
      <div styleName="bottom">
        <Button
          color="primary"
          onClick={() => { dispatch(setNewGame()); }}
          styleName="button"
          variant="outlined"
        >
          New game
        </Button>
        <Button
          color="primary"
          onClick={() => { dispatch(setShowSettings(!showSettings)); }}
          startIcon={<SettingsIcon />}
        >
          Settings
        </Button>
      </div>
      <div styleName="bottom">
        <DownloadPdfButton elementId="#sudokuTable" fileName="sudoku.pdf">
          Download Sodoku PDF
        </DownloadPdfButton>
        <DownloadJpegButton elementId="#sudokuTable" fileName="sudoku.jpeg">
          Download Sodoku JPEG
        </DownloadJpegButton>
      </div>
      <div styleName="showSolution">
        <Button onClick={() => { setShowSolution(!showSolution); }} variant="contained">
          {showSolution ? (
            <>Hide Solution</>
          ) : (
            <>Show Solution</>
          )}
        </Button>
      </div>
      {showSolution && solutionBoard && (
        <>
          <SudokuTable
            id="solutionTable"
            isSolutionTable
            solutionBoard={solutionBoard}
            sudokuBoard={sudokuBoard}
          />
          <div styleName="bottom">
            <DownloadPdfButton elementId="#solutionTable" fileName="solution.pdf">
              Download Solution PDF
            </DownloadPdfButton>
            <DownloadJpegButton elementId="#solutionTable" fileName="solution.jpeg">
              Download Solution JPEG
            </DownloadJpegButton>
          </div>
        </>
      )}
    </>
  );
}
