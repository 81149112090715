import './styles.module.css';

import { Button, Link, Snackbar } from '@material-ui/core';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';

export default function GDPR() {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!Cookies.get('acceptedCookies')) {
      setIsOpen(true);
    }
  }, []);

  return (
    <Snackbar
      action={(
        <Button
          color="primary"
          onClick={() => {
            Cookies.set('acceptedCookies', 'true');
            setIsOpen(false);
          }}
          size="small"
          variant="contained"
        >
          Confirm
        </Button>
      )}
      anchorOrigin={{
        vertical   : 'bottom',
        horizontal : 'right'
      }}
      message={(
        <span>
          more-than-sudoku.com uses cookies. By using our site you agree to our&nbsp;
          <Link color="inherit" href="/privacy_policy" target="_blank" underline="always">privacy policy</Link>
          .
        </span>
)}
      open={isOpen}
      styleName="snackbar"
    />
  );
}
