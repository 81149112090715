import { createMuiTheme, StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

const theme = createMuiTheme({
  typography : {
    fontFamily : 'Dosis, sans-serif'
  }
});

export default function ThemeWrap({ children }) {
  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </StylesProvider>
  );
}

ThemeWrap.propTypes = {
  children : PropTypes.node.isRequired
};
