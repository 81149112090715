import React from 'react';
import ReactDOM from 'react-dom';

import GDPR from '../GDPR';
import ThemeWrap from '../ThemeWrap';
import Home from './Home';

ReactDOM.render(
  <ThemeWrap>
    <GDPR />
    <Home />
  </ThemeWrap>,
  document.getElementById('root')
);
