import produce from 'immer';

import difficulties from '../../configs/enums/difficulties';
import getChineseNumbers from '../../utils/getChineseNumbers';
import naturalNumbersArray from '../../utils/naturalNumbersArray';
import { decompress } from '../../utils/sudokuCompression';

const isChinese = window.location && window.location.pathname === '/customize/chinese';

const initialState = {
  characters    : isChinese ? getChineseNumbers(9) : naturalNumbersArray(9),
  difficulty    : difficulties.EASY,
  font          : 'Dosis',
  isChinese,
  sudokuBoard   : null,
  solutionBoard : null,
  showMistakes  : true,
  showSettings  : false
};

if (window.location && window.location.hash.slice(1)) {
  try {
    const decompressedResult = decompress(window.location.hash.slice(1));
    initialState.sudokuBoard = decompressedResult.sudoku;
    initialState.solutionBoard = decompressedResult.solution;
    initialState.characters = naturalNumbersArray(decompressedResult.sudoku.length);
  } catch (e) {
    console.log(e); // eslint-disable-line no-console
    console.log('decompression failed'); // eslint-disable-line no-console
  }
}

export default produce((draft, action) => {
  const { payload, type } = action;
  switch (type) {
    case 'SET_BOARDS':
      draft.sudokuBoard = payload.sudokuBoard;
      draft.solutionBoard = payload.solutionBoard;
      return draft;
    case 'SET_CHARACTERS':
      draft.characters = payload.characters;
      return draft;
    case 'SET_DIFFICULTY':
      draft.difficulty = payload.difficulty;
      return draft;
    case 'SET_FONT':
      draft.font = payload.font;
      return draft;
    case 'SET_SHOW_SETTINGS':
      draft.showSettings = payload.showSettings;
      return draft;
    default:
      return draft;
  }
}, initialState);
