import { times } from 'lodash';

const mapping = {};
times(52, (index) => {
  let key;
  if (index < 26) {
    key = String.fromCharCode(index + 65);
  } else {
    key = String.fromCharCode(index - 26 + 97);
  }
  let value;
  if (index < 10) {
    value = `0${index}`;
  } else {
    value = `${index}`;
  }
  mapping[key] = value;
});

const reverseMapping = {};
Object.keys(mapping).forEach((key) => {
  reverseMapping[mapping[key]] = key;
});

export const compress = (sudoku, solution) => {
  let returnString = JSON.stringify(sudoku);
  returnString = returnString.replace(/,/g, '');
  returnString = returnString.replace(/\[|\]/g, '');
  returnString = returnString.replace(/^,/, '');
  returnString = returnString.replace(/,$/, '');
  returnString = returnString.replace(/"\."/g, '0');
  returnString = `${returnString}-`;

  sudoku.forEach((row, rowIndex) => {
    row.forEach((entry, columnIndex) => {
      if (entry === '.') {
        returnString = `${returnString}${solution[rowIndex][columnIndex]}`;
      }
    });
  });

  Object.keys(reverseMapping).forEach((key) => {
    const checkerRegex = new RegExp(key, 'g');
    returnString = returnString.replace(checkerRegex, reverseMapping[key]);
  });

  return returnString;
};

export const decompress = (string) => {
  let repopulatedString = string;

  Object.keys(mapping).forEach((key) => {
    const checkerRegex = new RegExp(key, 'g');
    repopulatedString = repopulatedString.replace(checkerRegex, mapping[key]);
  });

  const [boardString, solution] = repopulatedString.split('-');

  const size = boardString.length ** 0.5;

  const reconstructedBoard = [];
  const boardArray = boardString.replace(/0/g, '.').split('');
  times(size, () => {
    reconstructedBoard.push(boardArray.splice(0, size));
  });

  let solutionString = boardString;
  const reconstructedSolution = [];
  const solutionArray = solution.split('');
  solutionArray.forEach((value) => {
    solutionString = solutionString.replace('0', value);
  });
  const solutionBoardArray = solutionString.replace(/0/g, '.').split('');
  times(size, () => {
    reconstructedSolution.push(solutionBoardArray.splice(0, size));
  });

  return {
    sudoku   : reconstructedBoard,
    solution : reconstructedSolution
  };
};
