import { times } from 'lodash';

export default function getQuadrantValues({ board, columnIndex, rowIndex }) {
  const squareValues = [];

  if (board.length === 9) {
    const startingRowIndex = Math.floor(rowIndex / 3) * 3;
    const startingColumnIndex = Math.floor(columnIndex / 3) * 3;

    times(3, (rowNumber) => {
      times(3, (columNumber) => {
        const boardRowIndex = startingRowIndex + rowNumber;
        const boardColumnIndex = startingColumnIndex + columNumber;
        squareValues.push(board[boardRowIndex][boardColumnIndex]);
      });
    });
  } else if (board.length === 6) {
    const startingRowIndex = Math.floor(rowIndex / 2) * 2;
    const startingColumnIndex = Math.floor(columnIndex / 3) * 3;

    times(2, (rowNumber) => {
      times(3, (columNumber) => {
        const boardRowIndex = startingRowIndex + rowNumber;
        const boardColumnIndex = startingColumnIndex + columNumber;
        squareValues.push(board[boardRowIndex][boardColumnIndex]);
      });
    });
  }

  return squareValues;
}
