import { Button } from '@material-ui/core';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setNewGame } from '../../store/actions';
import { getFont } from '../../store/selectors';
import victoryConfetti from '../utils/victoryConfetti';
import styles from './SudokuTable.module.css';
import TableBox from './TableBox';

export default function SudokuTable({
  id,
  isInteractive,
  isSolutionTable,
  showMistakes,
  solutionBoard,
  sudokuBoard
}) {
  const dispatch = useDispatch();
  const font = useSelector(getFont);

  const [inputValues, setInputValues] = useState({});
  const [focusCoordinate, setFocusCoordinate] = useState([0, 0]);
  const [isDone, setIsDone] = useState(false);
  const tableRef = useRef();
  const size = sudokuBoard.length;

  useEffect(() => {
    setInputValues({});
    setFocusCoordinate([0, 0]);
    setIsDone(false);
  }, [sudokuBoard]);

  useEffect(() => {
    if (focusCoordinate) {
      const rowElement = tableRef.current.children[focusCoordinate[0]];
      const boxElement = rowElement.children[focusCoordinate[1]];
      if (boxElement.firstElementChild) {
        boxElement.firstElementChild.focus();
      } else {
        boxElement.focus();
      }
    }
  }, [focusCoordinate]);

  useEffect(() => {
    if (!solutionBoard) {
      return;
    }
    const boardToCheck = cloneDeep(sudokuBoard);
    Object.keys(inputValues).forEach((key) => {
      const [rowIndexString, columnIndexString] = key.split('x');
      const rowIndex = Number(rowIndexString);
      const columnIndex = Number(columnIndexString);

      boardToCheck[rowIndex][columnIndex] = Number(inputValues[key]);
    });

    if (JSON.stringify(boardToCheck).replace(/"/g, '') === JSON.stringify(solutionBoard).replace(/"/g, '')) {
      victoryConfetti();
      setIsDone(true);
      document.getElementsByTagName('input')[0].focus();
      document.getElementsByTagName('input')[0].blur();
    }
  }, [inputValues]);

  return (
    <div ref={tableRef} className={styles.table} id={id} style={{ fontFamily : `${font}, sans-serif` }}>
      {sudokuBoard.map((row, rowIndex) => (
        <div
          key={rowIndex} // eslint-disable-line react/no-array-index-key
          className={styles.row}
        >
          {row.map((boardValue, columnIndex) => (
            <TableBox
              key={`${rowIndex}x${columnIndex}`} // eslint-disable-line react/no-array-index-key
              boardValue={boardValue === undefined ? '' : String(boardValue)}
              correctValue={solutionBoard && String(solutionBoard[rowIndex][columnIndex])}
              inputValue={inputValues[`${rowIndex}x${columnIndex}`] || ''}
              isForSolutionTable={isSolutionTable}
              isInteractive={isInteractive}
              onKeyDownInner={(event) => {
                const { currentTarget, key } = event;
                const { selectionEnd, selectionStart } = currentTarget;
                const noHighlight = selectionStart === selectionEnd;

                event.stopPropagation();

                if (!noHighlight) {
                  return;
                }
                if (key === 'ArrowUp') {
                  setFocusCoordinate([Math.max(rowIndex - 1, 0), columnIndex]);
                  return;
                }
                if (key === 'ArrowDown') {
                  setFocusCoordinate([Math.min(rowIndex + 1, size - 1), columnIndex]);
                  return;
                }
                const inputValue = inputValues[`${rowIndex}x${columnIndex}`] || '';
                if ((inputValue.length <= 1 || selectionStart <= 0) && key === 'ArrowLeft') {
                  setFocusCoordinate([rowIndex, Math.max(columnIndex - 1, 0)]);
                  return;
                }
                if ((inputValue.length <= 1 || selectionStart === inputValue.length) && key === 'ArrowRight') {
                  setFocusCoordinate([rowIndex, Math.min(columnIndex + 1, size - 1)]);
                }
              }}
              onKeyDownOuter={(event) => {
                const { key } = event;
                event.preventDefault();

                if (boardValue === '.') {
                  return;
                }
                if (key === 'ArrowUp') {
                  setFocusCoordinate([Math.max(rowIndex - 1, 0), columnIndex]);
                  return;
                }
                if (key === 'ArrowDown') {
                  setFocusCoordinate([Math.min(rowIndex + 1, size - 1), columnIndex]);
                  return;
                }
                if (key === 'ArrowLeft') {
                  setFocusCoordinate([rowIndex, Math.max(columnIndex - 1, 0)]);
                  return;
                }
                if (key === 'ArrowRight') {
                  setFocusCoordinate([rowIndex, Math.min(columnIndex + 1, size - 1)]);
                }
              }}
              setInputValue={(newValue) => {
                setInputValues({ ...inputValues, [`${rowIndex}x${columnIndex}`] : newValue });
              }}
              showMistakes={showMistakes}
            />
          ))}
        </div>
      ))}
      <div className={styles.topBorder} />
      <div className={styles.bottomBorder} />
      <div className={styles.rightBorder} />
      <div className={styles.leftBorder} />
      <div className={styles.firstRowDivider} />
      <div className={styles.secondRowDivider} />
      <div className={size === 9 ? styles.firstColumnDivider : styles.firstColumnDivider6x6} />
      {size === 9 && (
        <div className={styles.secondColumnDivider} />
      )}
      {isDone && (
        <div className={styles.doneOverlay}>
          <Button
            color="primary"
            onClick={() => { dispatch(setNewGame()); }}
            variant="contained"
          >
            New game
          </Button>
        </div>
      )}
    </div>
  );
}

SudokuTable.propTypes = {
  id              : PropTypes.string.isRequired,
  solutionBoard   : PropTypes.arrayOf(PropTypes.array),
  sudokuBoard     : PropTypes.arrayOf(PropTypes.array).isRequired,
  isInteractive   : PropTypes.bool,
  isSolutionTable : PropTypes.bool,
  showMistakes    : PropTypes.bool
};

SudokuTable.defaultProps = {
  solutionBoard   : null,
  isInteractive   : false,
  isSolutionTable : false,
  showMistakes    : false
};
