import './Nav.module.css';

import {
  Button,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper
} from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import React, { useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

export default function Nav() {
  const mobileMenuRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleClose = (event) => {
    if (mobileMenuRef.current && mobileMenuRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const scrollToTop = () => {
    const animationDuration = 250;
    const initialScrollTop = document.documentElement.scrollTop;
    const startTime = Date.now();
    const easeInOutSine = (progress) => -(Math.cos(Math.PI * progress) - 1) / 2;
    const scrollingAnimationCallback = () => {
      const passedTime = Date.now() - startTime;
      if (document.documentElement.scrollTop > 0) {
        const progress = Math.min(passedTime / animationDuration, 1);
        document.documentElement.scrollTop = initialScrollTop * (1 - easeInOutSine(progress));
        window.requestAnimationFrame(scrollingAnimationCallback);
      }
    };
    window.requestAnimationFrame(scrollingAnimationCallback);
  };

  const isSmallScreen = useMediaQuery({ query : '(max-width: 540px)' });

  return (
    <>
      <nav styleName="nav">
        <a href="/" styleName="logoLink">
          <div styleName="logo" />
          <div styleName="logoText">More than Sudoku</div>
        </a>
        <div styleName="spacer" />
        { isSmallScreen ? (
          <>
            <IconButton
              ref={mobileMenuRef}
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
              size="small"
              styleName="menu-icon-button"
            >
              <MenuIcon />
            </IconButton>
            <Popper
              anchorEl={mobileMenuRef.current}
              disablePortal
              open={open}
              position="bottom-end"
              role={undefined}
              transition
            >
              {({ TransitionProps }) => (
                <Grow
                  {...TransitionProps}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                        {window.location && window.location.pathname === '/' ? (
                          <MenuItem
                            component="button"
                            onClick={(e) => {
                              handleClose(e);
                              scrollToTop();
                            }}
                          >
                            Play Sudoku
                          </MenuItem>
                        ) : (
                          <MenuItem
                            component="a"
                            href="/"
                            onClick={handleClose}
                          >
                            Play Sudoku
                          </MenuItem>
                        )}
                        <MenuItem
                          component="a"
                          href="/customize"
                          onClick={handleClose}
                        >
                          Customize
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </>
        ) : (
          <>
            {window.location && window.location.pathname === '/' ? (
              <Button
                color="primary"
                onClick={scrollToTop}
                styleName="button"
                variant="contained"
              >
                Play Sudoku
              </Button>
            ) : (
              <Button color="primary" href="/" styleName="button" variant="contained">Play Sudoku</Button>
            )}
            <Button color="primary" href="/customize" styleName="button" variant="outlined">Customize</Button>
          </>
        )}
      </nav>
      <div styleName="vertical-spacer" />
    </>
  );
}
