import PropTypes from 'prop-types';
import React from 'react';

import Footer from '../Footer';
import Nav from '../Nav';

export default function NavigationWrap({ children }) {
  return (
    <>
      <Nav />
      {children}
      <Footer />
    </>
  );
}

NavigationWrap.propTypes = {
  children : PropTypes.node.isRequired
};
