import './styles.module.css';

import {
  SwipeableDrawer,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setShowSettings } from '../../store/actions';
import { getShowSettings } from '../../store/selectors';

export default function SettingsDrawer({ children, title }) {
  const dispatch = useDispatch();
  const showSettings = useSelector(getShowSettings);

  return (
    <SwipeableDrawer
      anchor="right"
      onClose={() => { dispatch(setShowSettings(false)); }}
      onOpen={() => { dispatch(setShowSettings(true)); }}
      open={showSettings}
    >
      <div styleName="drawer-wrap">
        <Typography
          component="h2"
          styleName="drawer-title"
          variant="h5"
        >
          {title}
        </Typography>
        {children}
      </div>
    </SwipeableDrawer>
  );
}

SettingsDrawer.propTypes = {
  title    : PropTypes.string.isRequired,
  children : PropTypes.node.isRequired
};
