import {
  clone, cloneDeep, sample, times
} from 'lodash';

import naturalNumbersArray from '../utils/naturalNumbersArray';
import getColumnValues from './utils/getColumnValues';
import getQuadrantValues from './utils/getQuadrantValues';

export default class SudokuBoard {
  constructor(size) {
    this.size = size;
    this.createCheckedBoard();
    this.solution = cloneDeep(this.board);
  }

  createCheckedBoard() {
    let board;
    let boardMade = false;
    while (!boardMade) {
      board = this.createUncheckedBoard();
      boardMade = true;
      board.forEach((row) => { // eslint-disable-line no-loop-func
        if (row.includes(undefined)) {
          boardMade = false;
        }
      });
    }

    this.board = board;
  }

  createUncheckedBoard() {
    const { size } = this;
    const emptyRow = [];
    times(size, () => { emptyRow.push('.'); });
    const board = [];
    times(size, () => { board.push(clone(emptyRow)); });

    board.forEach((row, rowIndex) => {
      row.forEach((rowItem, columnIndex) => {
        let possibleNumbers = naturalNumbersArray(size);

        // filter existing values in row
        possibleNumbers = possibleNumbers.filter((number) => !row.includes(number));

        // filter existing values in column
        const columnValues = getColumnValues({ board, columnIndex });
        possibleNumbers = possibleNumbers.filter((number) => !columnValues.includes(number));

        // filter exiting values in square
        const quadrantValues = getQuadrantValues({ board, rowIndex, columnIndex });
        possibleNumbers = possibleNumbers.filter((number) => !quadrantValues.includes(number));

        // assign value from remaining possible values
        board[rowIndex][columnIndex] = sample(possibleNumbers);
      });
    });

    return board;
  }

  pluck(numberToPluck) {
    const { board, size } = this;
    const newBoard = cloneDeep(board);
    let numberLeftToPluck = numberToPluck;
    const availableNumbers = [];
    times(size, (number) => { availableNumbers.push(number); });

    // make sure there is no filled row
    times(size, (rowIndex) => {
      if (!newBoard[rowIndex].includes('.') && numberLeftToPluck > 0) {
        const randomColumn = sample(availableNumbers);
        newBoard[rowIndex][randomColumn] = '.';
        numberLeftToPluck -= 1;
      }
    });

    // make sure there is no filled column
    times(size, (columnIndex) => {
      const columnValues = getColumnValues({ board : newBoard, columnIndex });
      if (!columnValues.includes('.') && numberLeftToPluck > 0) {
        const randomRow = sample(availableNumbers);
        newBoard[randomRow][columnIndex] = '.';
        numberLeftToPluck -= 1;
      }
    });

    // make sure there is no filled quadrant
    if (size === 9) {
      times(3, (startingRowIndexDividedByThree) => {
        const startingRowIndex = startingRowIndexDividedByThree * 3;
        times(3, (startingColumnIndexDividedByThree) => {
          const startingColumnIndex = startingColumnIndexDividedByThree * 3;
          const quadrantValues = getQuadrantValues({
            board,
            rowIndex    : startingRowIndex,
            columnIndex : startingColumnIndex
          });

          if (!quadrantValues.includes('.') && numberLeftToPluck > 0) {
            const randomRow = sample([startingRowIndex, startingRowIndex + 1, startingRowIndex + 2]);
            const randomColumn = sample([startingColumnIndex, startingColumnIndex + 1, startingColumnIndex + 2]);
            newBoard[randomRow][randomColumn] = '.';
            numberLeftToPluck -= 1;
          }
        });
      });
    } else if (size === 6) {
      times(2, (startingRowIndexDividedByThree) => {
        const startingRowIndex = startingRowIndexDividedByThree * 3;
        times(3, (startingColumnIndexDividedByThree) => {
          const startingColumnIndex = startingColumnIndexDividedByThree * 2;
          const quadrantValues = getQuadrantValues({
            board,
            rowIndex    : startingRowIndex,
            columnIndex : startingColumnIndex
          });

          if (!quadrantValues.includes('.') && numberLeftToPluck > 0) {
            const randomRow = sample([startingRowIndex, startingRowIndex + 1, startingRowIndex + 2]);
            const randomColumn = sample([startingColumnIndex, startingColumnIndex + 1]);
            newBoard[randomRow][randomColumn] = '.';
            numberLeftToPluck -= 1;
          }
        });
      });
    }

    while (numberLeftToPluck > 0) {
      const randomRow = sample(availableNumbers);
      const randomColumn = sample(availableNumbers);

      if (newBoard[randomRow][randomColumn] !== '.') {
        newBoard[randomRow][randomColumn] = '.';
        numberLeftToPluck -= 1;
      }
    }

    this.board = newBoard;
  }
}
