import {
  Button,
  ButtonGroup
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import difficulties from '../../configs/enums/difficulties';
import { setDifficulty } from '../../store/actions';
import { getDifficulty } from '../../store/selectors';

export default function DifficultyButtonGroup({ className }) {
  const dispatch = useDispatch();
  const difficulty = useSelector(getDifficulty);

  return (
    <ButtonGroup aria-label="outlined primary button group" className={className} color="primary">
      <Button
        disableElevation
        onClick={() => { dispatch(setDifficulty(difficulties.EASY)); }}
        variant={difficulty === difficulties.EASY ? 'contained' : 'outlined'}
      >
        Easy
      </Button>
      <Button
        disableElevation
        onClick={() => { dispatch(setDifficulty(difficulties.MEDIUM)); }}
        variant={difficulty === difficulties.MEDIUM ? 'contained' : 'outlined'}
      >
        Medium
      </Button>
      <Button
        disableElevation
        onClick={() => { dispatch(setDifficulty(difficulties.HARD)); }}
        variant={difficulty === difficulties.HARD ? 'contained' : 'outlined'}
      >
        Hard
      </Button>
    </ButtonGroup>
  );
}

DifficultyButtonGroup.propTypes = {
  className : PropTypes.string
};

DifficultyButtonGroup.defaultProps = {
  className : null
};
