import SudokuBoard from './SudokuBoard';

export default class SudokuGenerator {
  static generate({ numberToPluck, size } = {}) {
    const board = new SudokuBoard(size);
    board.pluck(numberToPluck);

    return { sudoku : board.board, solution : board.solution };
  }
}
