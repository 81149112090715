import './styles.module.css';

import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import downloadElementJpeg from '../utils/downloadElementJpeg';

export default function DownloadJpegButton({ children, elementId, fileName }) {
  const onDownloadJpegClick = async () => {
    const element = document.querySelector(elementId);
    downloadElementJpeg(element, fileName);
  };

  return (
    <Button
      onClick={onDownloadJpegClick}
      styleName="button"
      variant="outlined"
    >
      {children}
    </Button>
  );
}

DownloadJpegButton.propTypes = {
  children  : PropTypes.node.isRequired,
  elementId : PropTypes.string.isRequired,
  fileName  : PropTypes.string.isRequired
};
