import difficulties from '../configs/enums/difficulties';

export default function getNumberToPluck({ difficulty, size }) {
  if (size === 9) {
    switch (difficulty) {
      case difficulties.EASY:
        return 40;
      case difficulties.MEDIUM:
        return 45;
      case difficulties.HARD:
        return 60;
      default:
        return 40;
    }
  } else if (size === 6) {
    switch (difficulty) {
      case difficulties.EASY:
        return 15;
      case difficulties.MEDIUM:
        return 20;
      case difficulties.HARD:
        return 25;
      default:
        return 10;
    }
  }

  return 1;
}
