import PropTypes from 'prop-types';
import React from 'react';
import { Provider } from 'react-redux';

import createStore from '../../store';

export default function WithProvider({ children }) {
  return (
    <Provider store={createStore()}>
      {children}
    </Provider>
  );
}

WithProvider.propTypes = {
  children : PropTypes.node.isRequired
};
