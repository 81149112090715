import {
  Button,
  ButtonGroup
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setNewGame } from '../../store/actions';
import { getSize } from '../../store/selectors';

export default function SizeButtonGroup({ className }) {
  const dispatch = useDispatch();
  const size = useSelector(getSize);

  return (
    <ButtonGroup aria-label="outlined primary button group" className={className} color="secondary">
      <Button
        disableElevation
        onClick={() => { dispatch(setNewGame(9)); }}
        variant={size === 9 ? 'contained' : 'outlined'}
      >
        9x9
      </Button>
      <Button
        disableElevation
        onClick={() => { dispatch(setNewGame(6)); }}
        variant={size === 6 ? 'contained' : 'outlined'}
      >
        6x6
      </Button>
    </ButtonGroup>
  );
}

SizeButtonGroup.propTypes = {
  className : PropTypes.string
};

SizeButtonGroup.defaultProps = {
  className : null
};
