import './Home.module.css';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link,
  Paper,
  Typography
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import React from 'react';

import NavigationWrap from '../../NavigationWrap';
import PlayBoard from '../../PlayBoard';
import WithProvider from '../../WithProvider';

export default function HomePage() {
  return (
    <NavigationWrap>
      <WithProvider>
        <PlayBoard />
      </WithProvider>
      <div styleName="wrap">
        <Typography component="h1" gutterBottom variant="h5">Play or download Sudoku for free online!</Typography>
        <Typography paragraph>More than Sudoku provides a place to play Sudoku puzzles for free. We use a custom Sudoku generator that will create a new puzzle every time you visit!</Typography>
        <Typography component="h2" gutterBottom variant="h5">Create your own Sudoku puzzle</Typography>
        <Typography paragraph>
          Try creating your own custom Sudoku puzzle with tons of customization options including different characters and advanced options for how the puzzle is generated.&nbsp;
          <Link href="/customize">Try it out now!</Link>
        </Typography>
        <Typography component="h2" gutterBottom variant="h5">Commonly asked questions</Typography>
        <Paper elevation={0} styleName="paper">
          <Accordion>
            <AccordionSummary
              aria-controls="panel1a-content"
              expandIcon={<ExpandMoreIcon />}
              id="panel1a-header"
            >
              <Typography component="h3">How do you play Sudoku?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                The goal of Sudoku is to fill in all of the empty spaces with the missing numbers. However, there must not be any repeat numbers in a row, column, or box. This means that each row, column, and box will actually have one of each number.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              aria-controls="panel2a-content"
              expandIcon={<ExpandMoreIcon />}
              id="panel2a-header"
            >
              <Typography component="h3">Where did Sudoku come from?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                The modern version of Sudoku is said to have been invented by Howard Garns, an architect and freelance puzzle constructor from Connersville, Indiana. The puzzle game was picked up by a Japanese paper, where it gained popularity. Now Sudoku puzzles are played all over the world!
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              aria-controls="panel3a-content"
              expandIcon={<ExpandMoreIcon />}
              id="panel3a-header"
            >
              <Typography component="h3">Is Sudoku good for my brain?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Like many other logic puzzles, Sudoku puzzles require you to think and plan ahead, which can help keep your mind sharp. Doing the puzzle can be a good workout for your brain if your day to day tasks don&apos;t require you to think too much. It can also be something to direct your focus to, taking your mind off of your other stressors and worries in life.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Paper>
      </div>
    </NavigationWrap>
  );
}
