import { Create as CreateIcon } from '@material-ui/icons';
import { sortedUniq } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './TableBox.module.css';

const getInputFontSize = (length = 0) => {
  let fontSize = '20px';
  if (length > 1) {
    fontSize = '16px';
  }
  if (length > 5) {
    fontSize = '14px';
  }
  if (length > 6) {
    fontSize = '12px';
  }
  if (length > 7) {
    fontSize = '10px';
  }

  return fontSize;
};

export default function TableBox({
  boardValue,
  correctValue,
  inputValue,
  isForSolutionTable,
  isInteractive,
  onKeyDownInner,
  onKeyDownOuter,
  setInputValue,
  showMistakes
}) {
  const noBoadValue = boardValue === '.';
  const correctSolutionBox = noBoadValue && isForSolutionTable;
  let wrapClasses = styles.box;
  if (showMistakes && inputValue.length === 1 && inputValue !== correctValue) {
    wrapClasses += ` ${styles.mistake}`;
  }

  const wrapAttributes = isInteractive ? {
    role      : 'button',
    className : wrapClasses,
    tabIndex  : noBoadValue ? null : '0',
    onKeyDown : onKeyDownOuter
  } : {
    className : styles.box
  };

  return (
    <div {...wrapAttributes}>
      {(noBoadValue && !correctSolutionBox) ? (
        <>
          {isInteractive && (
            <input
              className={styles.innerInput}
              maxLength="9"
              onChange={(event) => {
                const { value } = event.target;
                if (value === '' || value.match(/^[1-9]+$/)) {
                  const newValue = sortedUniq(value.split('').sort()).join('');
                  setInputValue(newValue);
                }
              }}
              onFocus={(event) => {
                const { target } = event;
                target.selectionStart = target.value.length;
                target.selectionEnd = target.value.length;
              }}
              onKeyDown={onKeyDownInner}
              pattern="[0-9]*"
              style={{ fontSize : getInputFontSize(inputValue.length) }}
              value={inputValue}
            />
          )}
          {inputValue.length === 1 && (
            <div className={styles.userInputIndicator} />
          )}
          {inputValue.length > 1 && (
            <div className={styles.notesIndicator}>
              <CreateIcon fontSize="inherit" />
            </div>
          )}
        </>
      ) : (
        <>
          {correctSolutionBox && (
            <div className={styles.userInputIndicator} />
          )}
          {correctSolutionBox ? (
            <span styleName="solution">{correctValue}</span>
          ) : (
            boardValue
          )}
        </>
      )}
    </div>
  );
}

TableBox.propTypes = {
  boardValue         : PropTypes.string.isRequired,
  correctValue       : PropTypes.string,
  inputValue         : PropTypes.string.isRequired,
  onKeyDownOuter     : PropTypes.func.isRequired,
  onKeyDownInner     : PropTypes.func.isRequired,
  setInputValue      : PropTypes.func.isRequired,
  isForSolutionTable : PropTypes.bool.isRequired,
  isInteractive      : PropTypes.bool.isRequired,
  showMistakes       : PropTypes.bool.isRequired
};

TableBox.defaultProps = {
  correctValue : null
};
