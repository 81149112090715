import { times } from 'lodash';

import SudokuGenerator from '../../sudokuGenerator';
import getChineseNumbers from '../../utils/getChineseNumbers';
import getNumberToPluck from '../../utils/getNumberToPluck';
import naturalNumbersArray from '../../utils/naturalNumbersArray';
// import { compress } from '../../utils/sudokuCompression';
import {
  getCharacters,
  getDifficulty,
  getIsChinese,
  getSize
} from '../selectors';

export const setNewGame = (size) => (dispatch, getState) => {
  let state = getState();
  const currentSize = getSize(state);
  const isChinese = getIsChinese(state);
  const newSize = size || currentSize || 9;
  let newCharacters;

  if (newSize !== currentSize) {
    if (isChinese) {
      newCharacters = getChineseNumbers(newSize);
    } else {
      newCharacters = naturalNumbersArray(newSize);
    }
  }

  if (newCharacters) {
    dispatch({
      type    : 'SET_CHARACTERS',
      payload : {
        characters : newCharacters
      }
    });
  }

  state = getState();

  const characters = getCharacters(state);
  const difficulty = getDifficulty(state);

  const numberToPluck = getNumberToPluck({ difficulty, size : newSize });
  const { solution, sudoku } = SudokuGenerator.generate({
    size : newSize,
    numberToPluck
  });
  times(newSize, (rowIndex) => {
    times(newSize, (columnIndex) => {
      const sudokuEntry = sudoku[rowIndex][columnIndex];
      if (typeof sudokuEntry === 'number') {
        sudoku[rowIndex][columnIndex] = characters[sudokuEntry - 1];
      }
      const solutionEntry = solution[rowIndex][columnIndex];
      solution[rowIndex][columnIndex] = characters[solutionEntry - 1];
    });
  });
  dispatch({
    type    : 'SET_BOARDS',
    payload : {
      sudokuBoard   : sudoku,
      solutionBoard : solution
    }
  });
  // window.location.hash = compress(sudoku, solution);
};

export const setCharacters = (characters) => (dispatch, getState) => {
  dispatch({
    type    : 'SET_CHARACTERS',
    payload : {
      characters
    }
  });
  setNewGame()(dispatch, getState);
};

export const setDifficulty = (difficulty) => (dispatch, getState) => {
  dispatch({
    type    : 'SET_DIFFICULTY',
    payload : {
      difficulty
    }
  });
  setNewGame()(dispatch, getState);
};

export const setFont = (font) => ({
  type    : 'SET_FONT',
  payload : {
    font
  }
});

export const setShowSettings = (showSettings) => ({
  type    : 'SET_SHOW_SETTINGS',
  payload : {
    showSettings
  }
});
