const count = 200;
const defaults = {
  origin : { y : 0.7 }
};

async function fire(particleRatio, opts) {
  const { default : confetti } = await import('canvas-confetti');
  confetti({ ...defaults, ...opts, particleCount : Math.floor(count * particleRatio) });
}

export default function victoryConfetti() {
  fire(0.25, {
    spread        : 26,
    startVelocity : 55
  });
  fire(0.2, {
    spread : 60
  });
  fire(0.35, {
    spread : 100,
    decay  : 0.91
  });
  fire(0.1, {
    spread        : 120,
    startVelocity : 25,
    decay         : 0.92
  });
  fire(0.1, {
    spread        : 120,
    startVelocity : 45
  });
}
